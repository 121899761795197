<template>
 <CCol>
  <CRow v-show="loading">
    <CCol col="12">
      <CCard v-show="loading">
        <CCardBody>
          <p class="text-center" v-show="loading">
            <CSpinner />
          </p >   
        </CCardBody>
      </CCard>
    </CCol>    
  </CRow>
  <CRow v-show="!loading">
    <CToaster :autohide="3000">
      <template>              
        <div v-if="showWorkloadErrorToast">      
          <CToast          
            :key="1"
            :show="showWorkloadErrorToast"
            color="danger"
          >
            {{ workloadErrorToastMessage }}
          </CToast>
        </div>
      </template>
      <template>              
        <div v-if="showWorkloadSuccessToast">
          <CToast          
            :show="showWorkloadSuccessToast"
            color="success"
          >
            {{ workloadSuccessToastMessage }}
          </CToast>
        </div>
      </template>       
    </CToaster>          
    <CCol col="12">
      <CCard>
        <CCardBody>
          <CButton color="light" class="float-left  col-sm-2 col-xl-1" @click="goBack">Back</CButton>
          <CButton v-on:click="saveWorkload();" color="success" class="ml-5 float-left col-sm-4 col-xl-2" :disabled="!$v.$anyDirty || $v.workload.$invalid || $v.workloadSelectedRegions.$invalid || $v.workloadSelectedPillarPriorities.$invalid " >Save Workload</CButton>
          <CButton v-on:click="openWorkloadUpdate(workload.WorkloadId);" color="primary" class="ml-5 float-left col-sm-4 col-xl-2" :disabled="workloadNotifications.length == 0" >Update Workload</CButton>
        </CCardBody>
      </CCard>
    </CCol>    
    <CCol xs="12" sm="12" md="12" lg="6" xl="6">     
      <CCard>
        <CCardHeader>
          <b>Data</b>
        </CCardHeader>
        <CCardBody>
          <CForm>
            <template>
              <CRow>    
                  <CCol col="6">                         
                    <CInput
                      label="Workload Name"
                      placeholder="Name"
                      v-model="workload.FriendlyName"
                      :disabled="true"
                      description="Customer facing name which is used on the report"
                    >
                      <template #prepend-content><CIcon name="cil-user"/></template>
                    </CInput>       
                  </CCol>
                  <CCol col="6">                         
                    <CInput
                      label="Customer"
                      placeholder="Customer "
                      :value="customer.customer_name"
                      disabled
                    >
                      <template #prepend-content><CIcon name="cil-user"/></template>
                    </CInput>
                  </CCol>
              </CRow>
            </template>
            <template>
              <CRow>    
                  <CCol col="6">                         
                  <label class="typo__label">Lenses</label>
                    <ul id="array-rendering">
                      <li :key="lens" v-for="lens in workload.Lenses">
                        {{ getLensName(lens) }}
                      </li>
                    </ul>                    
                  </CCol>
                  <CCol col="6">          
                    <template>              
                        <div :class="{ 'invalid': $v.workloadSelectedPillarPriorities.$invalid }">
                        <label class="typo__label">Pillar Priorities</label>
                        <multiselect 
                          v-model="$v.workloadSelectedPillarPriorities.$model"                  
                          :options="availablePillars" 
                          track-by="pillar_id"
                          label="pillar_name"   
                          :searchable="false" 
                          :close-on-select="false"   
                          placeholder="Select the prioritiess of the pillars."
                          :multiple="true"
                          :hideSelected="false"                  
                          :allowEmpty="true"
                          :loading="loadingWorkloadSelectedPillarPriorities"
                        />                          
                        <label class="typo__label form__label mt-2" > <div style="color:#768192 !important; font-size: 80%"> Add and remove pillars to change the order.</div></label>                  
                      </div>
                    </template>                                 
                  </CCol>
              </CRow>
            </template>     
            <hr>
            <template>
              <CRow>    
                  <CCol col="6">                                           
                    <ASelect
                      label="Environment"
                      :options="workloadAvailableEnvironments"
                      :value.sync="workload.Environment"
                      :disabled="inputDisabled"
                      :is-valid="!$v.workload.Environment.$invalid"
                      placeholder="Please select the Environment of the Workload."
                      description="Set the Environment of the Workload."
                      @input="$v.workload.Environment.$touch"
                      >
                      <template #prepend-content><CIcon name="cil-globe-alt"/></template>
                      
                    </ASelect>                     
                  </CCol>
                  <CCol col="6">
                    <ASelect
                      label="Improvement Status"
                      :options="workloadAvailableImprovementStatus"
                      :value.sync="workload.ImprovementStatus"
                      :disabled="inputDisabled"
                      :is-valid="!$v.workload.ImprovementStatus.$invalid"
                      placeholder="Select the Improvement Status of the Workload"
                      description="Set or change the Improvement Status of the Workload."
                      @input="$v.workload.ImprovementStatus.$touch"
                      >
                      <template #prepend-content><CIcon name="cil-globe-alt"/></template>
                      
                    </ASelect>                                
                                   
                  </CCol>
              </CRow>
            </template>     
            <template>
              <CRow>    
                  <CCol col="6">                               
                    <div :class="{ 'invalid': $v.workloadSelectedRegions.$invalid }">
                      <label class="typo__label">AWS Regions</label>
                      <multiselect                       
                        v-model="$v.workloadSelectedRegions.$model"                  
                        :options="availableAwsRegions" 
                        track-by="region_id"
                        label="region_id"
                        group-values="regions"
                        group-label="region_area"
                        :group-select="true"     
                        :searchable="true" 
                        :close-on-select="false"   
                        placeholder="Pick or search AWS Regions"
                        :multiple="true"
                        :hideSelected="true"              
                        :allowEmpty="true"
                      />           
                      <label class="typo__label form__label mt-2" > <div style="color:#768192 !important; font-size: 80%"> Select the regions where the Workload is running in. Must be at least one.</div></label>

                    </div>
                  </CCol>
              </CRow>
            </template>   
      
            <template>
              <CRow alignVertical="center" >
                <CCol col="8">      
                  <CTextarea
                  label="Description"
                  v-model="$v.workload.Description.$model"
                  placeholder="Add a brief description about the Workload & the review."
                  description="Description about the Workload & the Well-Architected Review."
                  :is-valid="!$v.workload.Description.$invalid"  
                  />                                            
                </CCol>                
              </CRow>
            </template>
            <template>
              <CRow alignVertical="center" >                
                <CCol col="8" >      
                  <CTextarea
                    label="AWS Account IDs (optional)"
                    v-model="workload.AccountIds"        
                    :is-valid="!$v.workload.AccountIds.$invalid"  
                    placeholder="Add AWS Account IDs seperated by a comma, e.g. 123456789,123456789"
                    description="AWS Account IDs seperated by a comma, e.g. 123456789,123456789"
                    @input="$v.workload.AccountIds.$touch"
                  />                                          
                </CCol>                
              </CRow>
            </template>                                    
          </CForm>    
        </CCardBody>
      </CCard>
      <CCard>
        <CCardHeader>
          <b>Internal Data</b>
          <CButton class="float-right col-sm-2" variant="outline" @click="switchShowInternalData" color="primary">{{ showInternalDataText }}</CButton>
        </CCardHeader>
        <CCardBody> 
          <template >
            <CContainer v-show="showInternalData">
              <CRow >               
                  <CCol col="4" >      
                    <CInput
                        label="Last Update"
                        placeholder="Last Update"
                        :value="convertDate(workload.UpdatedAt)"
                        :disabled="true"
                      />                     
                  </CCol>                      
                  <CCol col="4">                      
                    <CInput
                        label="HAWK Region"
                        placeholder="HAWK Region"
                        :value="workload.WorkloadRegion"
                        :disabled="true"
                      />      
                  </CCol>    
                  <CCol col="4">                      
                    <CInput
                        label="Review Owner"
                        placeholder="Review Owner"
                        :value="workload.ReviewOwner"
                        :disabled="true"
                      />      
                  </CCol>                                              
              </CRow>          
              <CRow>               
                  <CCol col="6" >      
                    <CInput
                        label="Internal Name"
                        placeholder="Internal Name"
                        :value="workload.WorkloadName"
                        :disabled="true"
                      />                     
                  </CCol>                      
                  <CCol col="2" />                     
                  <CCol col="4" >      
                    <CInput
                        label="AWS Account Owner"
                        placeholder="AWS Account Owner"
                        :value="workload.Owner"
                        :disabled="true"
                      />                     
                  </CCol>  
              </CRow>           
              <CRow>
                <CCol col="8" >      
                  <CTextarea
                    label="Tags"
                    :value="JSON.stringify(workload.Tags, null, 2)"
                    :disabled="true"
                    description="The Tags added to the AWS WAfR Workload"
                    addInputClasses="tag_textarea"
                  />    
                </CCol>
              </CRow>
            </CContainer>
          </template>          
        </CCardBody>
      </CCard>                      
    </CCol>
    <CCol xs="12" sm="12" md="12" lg="6" xl="6">
      <CCard>
        <CCardHeader>
          <b>Workload Shares</b>
          <CButton class="float-right col-sm-2" variant="outline" @click="openCreateWorkloadShare" color="info">Share</CButton>          
        </CCardHeader>
        <CCardBody>
          <CDataTable
            hover
            striped
            :sorterValue="{ column: 'ShareId', asc: 'desc' }"
            :items="workloadShares"
            :fields="workloadSharesTableFields"
            :items-per-page="10"      
            :active-page="workloadSharesTabelActivePage"
            :pagination="{ doubleArrows: true, align: 'center'}"
            :loading=workloadSharesTableLoading
          >            
              <td slot="ShareId" slot-scope="{item}" >                  
                {{ item.ShareId }}
              </td> 
              <td slot="SharedWith" slot-scope="{item}" >                  
                {{ item.SharedWith }}
              </td> 
              <td slot="PermissionType" slot-scope="{item}" >                  
                {{ item.PermissionType }}
              </td>  
              <td slot="Status" slot-scope="{item}" >                  
                {{ item.Status }}
              </td>                                        
                          
              <td slot="share_delete" slot-scope="{item}">
                <CButton v-on:click="deleteWorkloadShare(workload.WorkloadId, item.ShareId)" :id="item.ShareId" color="danger"  style="display: flex;" :disabled="false"> <span class="material-icons md-18">delete</span></CButton>              
              </td>                                
          </CDataTable>          
        </CCardBody>
      </CCard>    
      <CCard>
        <CCardHeader>
          <b>Workload Milestones</b>
          <CButton class="float-right col-sm-2" variant="outline" @click="openCreateWorkloadMilestone" color="info">Create</CButton>
        </CCardHeader>
        <CCardBody >
          <CDataTable
            hover
            striped
            :sorterValue="{ column: 'MilestoneNumber', asc: 'desc' }"
            :items="workloadMilestones"
            :fields="workloadMilestonesTableFields"
            :items-per-page="10"      
            :active-page="workloadMilestonesTabelActivePage"
            :pagination="{ doubleArrows: true, align: 'center'}"
            :loading=workloadMilestonesLoading
          >            
              <td slot="MilestoneNumber" slot-scope="{item}" >                  
                {{ item.MilestoneNumber }}
              </td> 
              <td slot="MilestoneName" slot-scope="{item}" >                  
                {{ item.MilestoneName }}
              </td> 
              <td slot="RecordedAt" slot-scope="{item}" >                  
                {{ convertDate(item.RecordedAt) }}
              </td>                          
              <td slot="milestone_open" slot-scope="{item}">
                  <CButton v-on:click="openWorkloadMilestone(item)" :id="item.MilestoneNumber" color="dark">
                    <CIcon name="cil-magnifying-glass"/>
                  </CButton>
              </td>                                
              <td slot="milestone_delete" slot-scope="{item}">
                <CButton v-on:click="deleteWorkloadMilestone(item)" :id="item.report_id" color="danger"  style="display: flex;" :disabled="true"> <span class="material-icons md-18">delete</span></CButton>              
              </td>                                
          </CDataTable>          
        </CCardBody>
      </CCard>   
      <CCard>
        <CCardHeader>
          <b>Risk Count History</b>
          <CButton class="float-right col-sm-2" variant="outline" @click="exportWorkloadFindingsHistory" :disabled="true" color="info">Export</CButton>
        </CCardHeader>
        <CCardBody >
          <CChartBar
              style="height:400px"
              :datasets="workloadRiskHistoryBarChartDataset"
              :labels="workloadRiskHistoryBarChartLabels"
              :options="{ maintainAspectRatio: false, scales: { xAxes: [{ stacked: true } ], yAxes: [{ stacked: true } ] } }"
            />        
        </CCardBody>
      </CCard>          
    </CCol>    
  </CRow>
  <CModal
    title="Share Workload"
    color="dark"
    :show.sync="showCreateWorkloadShareModal">
    <CCard>
      <CCardBody>
        <CRow>
          <CCol col="12">
            <CInput
              label="Share with"
              placeholder="-- AWS Account ID --"
              v-model="shareWorkload.sharedWith"
              :disabled="false"
              :is-valid="!$v.shareWorkload.sharedWith.$invalid"
              description="Enter the AWS Account ID to share the Workload with.">
              <template #prepend-content><CIcon name="cil-user"/></template>
            </CInput>                
          </CCol>
        </CRow>              
        <CRow>
          <CCol col="12">
            <ASelect
              label="Permission Type"
              :options="shareWorkloadAvailablePermissionTypes"
              :value.sync='shareWorkload.permissionType'
              :disabled="false"
              :is-valid="!$v.shareWorkload.permissionType.$invalid"
              placeholder="-- Permissions Type --"
              description="Please select the Permissions for the Workload Share">
              <template #prepend-content><CIcon name="cil-globe-alt"/></template>                      
            </ASelect>         
          </CCol>
        </CRow>
      </CCardBody> 
    </CCard>
      <div slot="footer" class="w-100">
        <CButton @click="showCreateWorkloadShareModal = false"  color="light" class="ml-1 mr-1 col-sm-2 float-left" >Close</CButton>  
        <CButton v-on:click="createWorkloadShare(workload.WorkloadId, shareWorkload.sharedWith, shareWorkload.permissionType);"  color="success" class="ml-1 mr-1 col-sm-2 float-right" :disabled="$v.shareWorkload.$invalid" >Create</CButton>  
      </div>
  </CModal>
  <CModal
    title="Create Milestone"
    color="dark"
    :show.sync="showCreateWorkloadMilestoneModal">
    <CCard>
      <CCardBody>            
        <CRow>
          <CCol col="12">
            <ASelect
              label="Milestone Type"
              :options="workloadMilestoneAvailableTypes"
              :value.sync='workloadMilestoneToCreate.milestoneType'
              :disabled="false"
              :is-valid="!$v.workloadMilestoneToCreate.milestoneType.$invalid"
              placeholder="-- Milestone Type --"
              description="Please select the Permissions for the Workload Share"
              @change="workloadMilestoneTypeChanged">
              <template #prepend-content><CIcon name="cil-globe-alt"/></template>                      
            </ASelect>         
          </CCol>
        </CRow>
        <CRow>
          <CCol col="12">
            <CInput
              label="Milestone Name"
              placeholder="-- Milestone Name --"
              :value.sync="workloadMilestoneToCreate.milestoneName"
              :disabled="workloadMilestoneToCreate.milestoneType != 'CUSTOM'"
              :is-valid="!$v.workloadMilestoneToCreate.milestoneName.$invalid"
              description="Add me">
              <template #prepend-content><CIcon name="cil-user"/></template>
            </CInput>                
          </CCol>
        </CRow>           
      </CCardBody> 
    </CCard>
      <div slot="footer" class="w-100">
        <CButton @click="showCreateWorkloadMilestoneModal = false"  color="light" class="ml-1 mr-1 col-sm-2 float-left" >Close</CButton>  
        <CButton v-on:click="createWorkloadMilestone(workload.WorkloadId, workloadMilestoneToCreate.milestoneName);"  color="success" class="ml-1 mr-1 col-sm-2 float-right" :disabled="$v.workloadMilestoneToCreate.$invalid" >Create</CButton>  
      </div>
  </CModal>  
  <CModal
    :title="workloadMilestoneModalItem.MilestoneName"
    color="dark"
    :show.sync="showWorkloadMilestoneModal"
    size="xl"
  >
    <p class="text-center" v-show="workloadMilestonesLoading">
      <CSpinner />
    </p >   
        <span v-show="!workloadMilestonesLoading">      
          <CCard>
              <CRow class="form-group" >
                <CCol :col="4">
                  <CCardHeader>
                    <strong>Review Owner</strong>
                  </CCardHeader>
                  <CCardBody>
                    {{ workloadMilestoneModalItemWorkload.ReviewOwner }}
                  </CCardBody>                
                </CCol>
                <CCol :col="4">
                  <CCardHeader>
                    <strong>Improvement Status</strong>
                  </CCardHeader>
                  <CCardBody>
                    {{ workloadAvailableImprovementStatus[workloadMilestoneModalItemWorkload.ImprovementStatus] }}                                                          
                  </CCardBody>                
                </CCol>                
                <CCol :col="4">
                  <CCardHeader>
                    <strong>Updated</strong>
                  </CCardHeader>
                  <CCardBody>
                    {{ convertDate(workloadMilestoneModalItemWorkload.UpdatedAt) }}
                  </CCardBody>
                </CCol>
              </CRow>
          </CCard>           
          <CCard>
            <CRow class="form-group" >
            <CCol :col="4">
                <CCardHeader>
                  <strong>Workload Name</strong>
                </CCardHeader>
                <CCardBody>
                  {{ workload.FriendlyName }} / {{ workloadMilestoneModalItemWorkload.WorkloadName }}
                </CCardBody>
              </CCol>                          
              <CCol :col="8">
                <CCardHeader>
                  <strong>Description</strong>
                </CCardHeader>
                <CCardBody>
                  {{ workloadMilestoneModalItemWorkload.Description }}
                </CCardBody>
              </CCol>              
            </CRow>
          </CCard> 
          <CCard> 
            <CRow class="form-group" >
              <CCol :col="6">
                <CCardHeader>
                  <strong>Environment</strong>
                </CCardHeader>
                <CCardBody>
                  {{ workloadMilestoneModalItemWorkload.Environment }}
                </CCardBody>                
              </CCol>
              <CCol :col="6">
                <CCardHeader>
                <strong>AWS Regions</strong>
                </CCardHeader>
                <CCardBody>
                  <ul id="array-rendering">
                    <li :key="region" v-for="region in workloadMilestoneModalItemWorkload.AwsRegions">
                      {{ region }}
                    </li>
                  </ul>
                </CCardBody>                
              </CCol>
            </CRow>
          </CCard>             
          <CCard>
            <CRow class="form-group" >
              <CCol :col="6">
                <CCardHeader>
                  <strong>Lenses</strong>
                </CCardHeader>
                <CCardBody>
                  <ul id="array-rendering">
                    <li :key="lens" v-for="lens in workloadMilestoneModalItemWorkload.Lenses">
                      {{ getLensName(lens) }}
                    </li>
                  </ul>                        
                </CCardBody>                
              </CCol>
              <CCol :col="6">
                <CCardHeader>
                  <strong>Pillar Priorities</strong>
                </CCardHeader>
                <CCardBody>
                  <ul id="array-rendering">
                    <li :key="pillar" v-for="pillar in workloadMilestoneModalItemWorkload.PillarPriorities">
                      {{ workloadAvailablePillars.find(x => x.pillar_id === pillar).pillar_name }}
                    </li>
                  </ul>
                </CCardBody>                
              </CCol>
            </CRow>
          </CCard>
          <CCard>
            <CCardHeader>
              <b>Risk Count</b>
              <!-- <CButton class="float-right col-sm-2" variant="outline" @click="exportWorkloadFindingsHistory" :disabled="workloadRiskHistoryBarChartDataset.length === 0" color="info">Export</CButton> -->
            </CCardHeader>
            <CCardBody >
                <CChartPie
                  :datasets="workloadMilestoneRiskPieDataset"
                  :labels="workloadMilestoneRiskPieLabels"
                  :options="{ aspectRatio: 4 }"
                />    
            </CCardBody>
          </CCard>                              
        </span>

      <div slot="footer" class="w-100">
          <CButton @click="showWorkloadMilestoneModal = false"  color="light" class="ml-1 mr-1 float-right col-sm-2" >Close</CButton>  
      </div>
    </CModal>     
  </CCol>    
</template>


<script>
import ASelect from '@/components/forms/ASelect.vue'
import Multiselect from 'vue-multiselect'
import WorkloadService from '@/services/workload.services';
import CustomerService from '@/services/customer.services';
import LensService from '@/services/lense.services';
import moment from 'moment';
import { CChartPie, CChartBar } from '@coreui/vue-chartjs'
import { required, requiredIf, sameAs, minLength, maxLength, integer, helpers } from 'vuelidate/lib/validators'
const awsAccountIdValidator = helpers.regex('alphaNumAndDot', /^(?:[0-9]{12})$/i);
const awsAccountIdsValidator = helpers.regex('alphaNumAndDot', /^(?:[0-9]{12},?)*$/i);


export default {
  components: {
    ASelect,
    Multiselect,
    CChartPie,
    CChartBar
  },  
  data() {
    return {
      saveDisabled: true,
      lenses: {},
      workloadSaved: false,
      workloadSavedFailed: false,
      inputDisabled: false,
      loading: true,
      showInternalDataText: "Show",
      showInternalData: false,
      workload: {
        Lenses: [ "N/A" ],
        AccountIds: [],        
      },
      customer: {},
      workloadNotifications: [],
      availableLenses: [],
      availablePillars: [],
      shareWorkload: {},
      workloadShares: [],      
      workloadMilestones: [],
      availableAwsRegions: [],
      workloadSelectedRegions: [],
      workloadSelectedPillarPriorities: [],
      loadingWorkloadSelectedPillarPriorities: true,
      workloadAvailableLenses: [],
      workloadAvailablePillars: [],
      workloadAvailableImprovementStatus: { },  
      workloadAvailableRegions: [ "eu-central-1", "us-east-1", "ap-southeast-1" ],
      workloadAvailableEnvironments: { 'PRODUCTION': 'Production', 'PREPRODUCTION': 'Pre-Production' },
      shareWorkloadAvailablePermissionTypes: {"READONLY": "Read-Only", "CONTRIBUTOR": 'Contributor' },
      workloadMilestonesTabelActivePage: 1,
      workloadMilestonesLoading: true,
      workloadMilestonesTableFields: [
        { key: 'MilestoneNumber', label: 'Number', _style:'width:10%' },
        { key: 'MilestoneName', label: 'Name', _style:'width:20%', filter: false   },
        { key: 'RecordedAt', label: 'Created', _style:'width:20%', filter: false,  },
        { key: 'milestone_open', label: 'Milestone',  _style: 'width:1%', sorter: false, filter: false },
        { key: 'milestone_delete', label: '',  _style: 'width:1%', sorter: false, filter: false }
      ],  
      showWorkloadMilestoneModal: false,
      workloadMilestoneModalItem: { },
      workloadMilestoneModalItemWorkload: {},
      showCreateWorkloadShareModal: false,
      showWorkloadShare: false,
      showWorkloadErrorToast: false,
      showWorkloadSuccessToast: false,
      workloadSuccessToastMessage: "",
      workloadErrorToastMessage: "",
      workloadSharesTableFields: [
        { key: 'ShareId', label: 'ID', _style:'width:10%' },
        { key: 'SharedWith', label: 'Shared With', _style:'width:20%', filter: false   },
        { key: 'PermissionType', label: 'Permissions', _style:'width:20%', filter: false,  },
        { key: 'Status', label: 'Status',  _style: 'width:1%', sorter: false, filter: false },
        { key: 'share_delete', label: '',  _style: 'width:1%', sorter: false, filter: false }
      ],  
      workloadSharesTabelActivePage: 1,
      workloadSharesTableLoading: true,
      workloadRiskHistoryData: [],
      workloadRiskHistoryBarChartDataset: [],
      workloadRiskHistoryBarChartLabels: [],
      workloadRiskHistoryFilterBarChart: [],
      showCreateWorkloadMilestoneModal: false,
      workloadMilestoneAvailableTypes: {"AFTERREVIEW": "After Review", "CUSTOM": 'Custom' },
      workloadMilestoneTypeNameMap: {"AFTERREVIEW": "After_Review_1"},
      workloadMilestoneToCreate: {  },
      workloadMilestoneRiskPieBackgroundColors: [
            '#1c7685',
            '#cc0000',
            '#ff9900',
            '#38761d',
            '#707272'
      ],
      workloadMilestoneRiskPieLabels: [],
      workloadMilestoneRiskPieDataset:  [],


    };
  },
  setup: () => ({ v$: useVuelidate() }),
  validations: {
    workloadSelectedRegions:{
      required,
      minLength: minLength(1),

    },
    workloadSelectedPillarPriorities:{
      required,
      minLength: minLength(6),
      maxLength: maxLength(6)
    },

    workload: {
      AccountIds: {
        awsAccountIdsValidator

      },
      Lenses: {
        required
      },
      Environment: {
        required
      },
      ImprovementStatus: {
        required,
      },
      Description: {
        required,
        $autoDirty: true,
      }
    },
    shareWorkload:{
      sharedWith: {
        required,
        awsAccountIdValidator
      },
      permissionType: {
        required
      }
    },
    workloadMilestoneToCreate: {
      milestoneType: {
        required
      },
      milestoneName: {
        required,
        minLength: minLength(5),
        maxLength: maxLength(30)

      }
    }
  },
  methods: {         
    goBack() {
      this.$router.push({path: '/workloads'})
    },  
    pageChange (val) {
      this.$router.push({ query: { page: val }});
    },         
    openWorkloadUpdate(workloadId) {
      this.$router.push({path: `/workloads/${workloadId}/update`});
    },     
    convertDate(date) {  
      return moment(date).format('DD.MM.YYYY - H:mm ')
    },    
    switchShowInternalData() {      
      if (this.showInternalData){
        this.showInternalDataText = "Show"
        this.showInternalData = !this.showInternalData
      } else {
        this.showInternalDataText = "Hide"
        this.showInternalData = !this.showInternalData

      }
    }, 
    getLensName(lensAlias) {
      let lens = this.availableLenses.find(o => o.lens_alias === lensAlias);

      if (lens === undefined) {
        return lensAlias
      }

      return lens.lens_name;
    
    },      
    workloadMilestoneTypeChanged(event){  
      this.workloadMilestoneToCreate.milestoneName = this.workloadMilestoneTypeNameMap[event.target.selectedOptions[0].dataset.key];
    },
    deleteWorkloadShare(workloadId, shareId){
      
      WorkloadService.deleteWorkloadShare(
        workloadId, 
        shareId,
        ).then(
          response => { 
            this.showWorkloadErrorToast = true;
            this.workloadErrorToastMessage = "Share has been deleted!"
            this.workloadShares = this.workloadShares.filter(share => share.ShareId != shareId);

          },
          error => {   
            this.showWorkloadErrorToast = true;
            this.workloadErrorToastMessage = "Share couldn't be deleted.";
            console.log(error);
          }      
      )          

    },
    openCreateWorkloadShare(){
      this.showCreateWorkloadShareModal = true;

    },
    openCreateWorkloadMilestone(){
      this.showCreateWorkloadMilestoneModal = true;

    },
    exportWorkloadFindingsHistory(){

    },
    async openWorkloadMilestone(workloadMilestone){
      this.showWorkloadMilestoneModal = true;

      await WorkloadService.getWorkloadMilestone(this.$route.params.id, workloadMilestone.MilestoneNumber).then(
              response => { 
                this.workloadMilestoneModalItem = response;
                this.workloadMilestoneModalItemWorkload = response.Workload;

                var labels = [];
                var data = [];

                for (var key in this.workloadMilestoneModalItemWorkload.RiskCounts) {
                  labels.push(this.getRisk(key))
                  data.push(this.workloadMilestoneModalItemWorkload.RiskCounts[key])
                }

                this.workloadMilestoneRiskPieDataset = [
                  {
                    backgroundColor: this.workloadMilestoneRiskPieBackgroundColors,
                    data: data
                  }
                ];

                this.workloadMilestoneRiskPieLabels = labels;         
                this.workloadMilestoneModalLoading = false;  

              },
              error => {   
                console.log(error)            
              }      
            )        

    },
     
    async processSelectedAwsRegions() {
      for (var key in this.workload.AwsRegions){
        this.workloadSelectedRegions.push({ 'region_id': this.workload.AwsRegions[key] })
      }
    },
    async processPillarPriorities() {
      for (var key in this.workload.PillarPriorities){
        let pillar = this.availablePillars.find(o => o.pillar_id === this.workload.PillarPriorities[key]);

        var tmp = {
          pillar_name: pillar.pillar_name,
          pillar_id: this.workload.PillarPriorities[key]
        }

        this.workloadSelectedPillarPriorities.push(tmp);
      }
      this.availablePillars = structuredClone(this.workloadSelectedPillarPriorities);
      this.loadingWorkloadSelectedPillarPriorities = false;
    },    
    async saveWorkload() {
     
      let tmpWorkloadAccountIds = [];

      if (this.workload.AccountIds !== undefined) {
        tmpWorkloadAccountIds = this.workload.AccountIds.split(',')
      }

      let tmpWorkloadPillarPriorities = [];
      for (var key in this.workloadSelectedPillarPriorities){
        tmpWorkloadPillarPriorities.push(this.workloadSelectedPillarPriorities[key].pillar_id)
      };

      let tmpWorkloadAwsRegions = [];
      for (var key in this.workloadSelectedRegions){
        tmpWorkloadAwsRegions.push(this.workloadSelectedRegions[key].region_id)
      };

      WorkloadService.saveWorkload(
        this.$route.params.id, 
        this.workload.Environment, 
        this.workload.Description, 
        this.workload.ImprovementStatus,
        tmpWorkloadPillarPriorities,
        tmpWorkloadAccountIds, 
        tmpWorkloadAwsRegions, 
        ).then(
          response => { 
            this.showWorkloadSuccessToast = true;
            this.workloadSuccessToastMessage = "Workload sucesfully saved!";
            this.inputDisabled = true;        
            this.$v.$reset();
          },
          error => {   
            this.showWorkloadErrorToast = true;
            this.errorToastMessage = "Error - Workload couldn't be saved";
            console.log(error);
          }      
      )         

    },      
    getRisk (risk) {
      switch (risk) {
        case 'HIGH': return 'High'
        case 'MEDIUM': return 'Medium'
        case 'NONE': return 'Well-Architected'
        case 'UNANSWERED': return 'Unanswered'
        case 'NOT_APPLICABLE': return 'Not applicable'
        default: 'Unanswered'
      }
    },            
    async createWorkloadMilestone(workload_id, milestone_name){
      WorkloadService.createWorkloadMilestone(
        workload_id, 
        milestone_name,
        ).then(
          response => { 
            this.showWorkloadSuccessToast = true;
            this.workloadSuccessToastMessage = "Milestone sucesfully created!";
            this.showCreateWorkloadMilestoneModal = false;
            this.workloadMilestonesTableLoading = true;
            WorkloadService.getWorkloadMilestones(this.$route.params.id).then(
              response => { 
                this.workloadMilestones = response;
                this.workloadMilestonesTableLoading = false;  
              },
              error => {   
                console.log(error)            
              }      
            )            
          },
          error => {   
            if(error.response.status === 409) {
              this.workloadErrorToastMessage = "Error - Milestone couldn't be created because it already exists.";
            } else {
              this.workloadErrorToastMessage = "Error - Milestone couldn't be created";
            }
            this.showWorkloadErrorToast = true;            
            console.log(error);
          }      
      )          
    },    
    async createWorkloadShare(workload_id, shared_with, permission_type){
      WorkloadService.createWorkloadShare(
        workload_id, 
        shared_with,
        permission_type, 
        ).then(
          response => { 
            this.showWorkloadSuccessToast = true;
            this.workloadSuccessToastMessage = "Workload sucesfully shared with "+ this.shareWorkload.sharedWith +" as "+ this.shareWorkload.permissionType +"!"
            this.showCreateWorkloadShareModal = false;
            this.workloadSharesTableLoading = true;
            WorkloadService.getWorkloadShares(this.$route.params.id).then(
              response => { 
                this.workloadShares = response;
                this.workloadSharesTableLoading = false;
              },
              error => {   
                console.log(error)            
              }      
            )            
          },
          error => {   
            if(error.response.status === 409) {
              this.workloadErrorToastMessage = "Error - Share couldn't be created because it already exists.";
            } else {
              this.workloadErrorToastMessage = "Error - Share couldn't be created";
            }
            this.showWorkloadErrorToast = true;
            console.log(error);
          }      
      )          
    },
    async processWorkloadRiskHistoryData(){
      await WorkloadService.getWorkloadHistory(this.workload.WorkloadId).then(
        response => { 
          this.workloadRiskHistoryData = response
        },
        error => {   
          console.log(error)   
        }
      );
      var labels = [];
      var data = {
        HIGH: [],
        MEDIUM: [],
        NONE: [],
        UNANSWERED: []
      };

      for (const [key, value] of this.workloadRiskHistoryData.Data.entries()) {      
        labels.push(this.convertDate(value.UpdatedAt))
        data.HIGH.push(value.RiskCounts.HIGH)
        data.MEDIUM.push(value.RiskCounts.MEDIUM)
        data.NONE.push(value.RiskCounts.NONE)
        data.UNANSWERED.push(value.RiskCounts.UNANSWERED)
      }

      this.workloadRiskHistoryBarChartDataset=[
        {
          data: data.HIGH,
          backgroundColor: '#cc0000',
          label: 'High',
        },
        {
          data: data.MEDIUM,
          backgroundColor: '#ff9900',
          label: 'Medium',
        },                  
        {
          data: data.NONE,
          backgroundColor: '#38761d',
          label: 'Well-Architcted',
        },
        {
          data: data.UNANSWERED,
          backgroundColor: '#707272',
          label: 'Unanswered',
        }
      ];
      
      this.workloadRiskHistoryBarChartLabels = labels;      
    }
  },
  created() { },
  async mounted() { 
    await WorkloadService.getWorkload(this.$route.params.id, true).then(
      response => { 
        this.workload = response;
      },
      error => {   
        console.log(error)   
      }      
    )
    await WorkloadService.getWorkloadsRegionsAws(false).then(
      response => { 
        for (var key in response){          
          var tmp = {}          
          tmp["region_area"] = key
          tmp['regions'] = response[key]        
          this.availableAwsRegions.push(tmp)
        }
      },
      error => {
        console.log(error)   
      }      
    );     
    await WorkloadService.getWorkloadsImprovementStatuses().then(
      response => { 
        this.workloadAvailableImprovementStatus = response
      },
      error => {   
        console.log(error)   
      }      
    );       
        
    await LensService.getLenses().then(
      response => { 
        this.availableLenses = response.lens_summaries;
      },
      error => {   
        console.log(error)   
      }      
    );
    await LensService.getPillars().then(
      response => { 
        this.availablePillars = response.pillars;
      },
      error => {   
        console.log(error)   
      }      
    );
    
    await CustomerService.getCustomer(this.workload.CustomerId).then(
      response => { 
        this.customer = response;
      },
      error => {   
        console.log(error)   
      }      
    );
    await WorkloadService.getWorkloadNotifications(this.$route.params.id).then(
      response => { 
        this.workloadNotifications = response.notifications;
      },
      error => {   
        console.log(error)   
      }      
    );
    await WorkloadService.getWorkloadShares(this.$route.params.id).then(
      response => { 
        this.workloadShares = response;
        this.workloadSharesTableLoading = false;
      },
      error => {   
        console.log(error)   
      }      
    );
    await WorkloadService.getWorkloadMilestones(this.$route.params.id).then(
      response => { 
        this.workloadMilestones = response;
        this.workloadMilestonesLoading = false;  
      },
      error => {   
        console.log(error)   
      }      
    )    
    await this.processWorkloadRiskHistoryData(),
    await this.processSelectedAwsRegions(),
    await this.processPillarPriorities()
    this.loading = false

  },
  watch: {
    $route: {
      immediate: true,
      handler (route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page)
        }
      }
    }
  },


};



</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style >
.tag_textarea {
  min-height: 160px;
}

</style>